import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import "bootstrap/dist/css/bootstrap.min.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PrenotaOra from "../components/prenotaOra"

import "../style/index.scss"

const IndexPage = ({ data }) => {
  return (
    <Layout page="">
      <Seo
        title="Supporto e Sostegno Psicologico"
        description="presentazione del dott. Jacopo Biraschi, dei suoi studi accademici e delle sue passioni, breve descrizione dei suoi ambiti di intervento e supporto psicologico quali, ad esempio, ansia, depressione e disturbi di personalit&agrave;, indirizzi dei due studi in cui riceve a Milano e Rho."
      />
      <div id="hero">
        <div id="title">
          <h1>Cambiare &egrave; possibile, stare meglio &egrave; possibile!</h1>
        </div>
        <div id="hero-pic">
          <Img
            className="pic"
            fluid={data.photo1.childImageSharp.fluid}
            loading="eager"
          />
        </div>
      </div>
      <div className="introduction">
        <div className="with-pic">
          <p>
            <strong>Forse sei qui perch&eacute;...</strong><br />
            In questo particolare momento della tua vita, ti stai ritrovando a fare i conti con difficolt&agrave; che fino a poco tempo fa non avevi.
            Azioni che fanno parte del tuo quotidiano e con le quali hai molta familiarit&agrave;, sono improvvisamente diventate difficili,
            pesanti… quasi insostenibili! &Egrave; tutto molto strano perch&eacute; non hai mai avuto problemi di questo tipo e quasi ti vergogni
            ad ammetterlo, per&ograve; adesso la situazione &eacute; esattamente questa e non puoi proprio farne a meno.
          </p>
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.photo2.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.photo3.childImageSharp.fluid}
              loading="eager"
            />
          </div>
          <p>
            <strong>La vita &egrave; troppo breve per essere infelici</strong><br />
            La cosa peggiore &egrave; che non ti senti pi&ugrave; te stess*, come se non sapessi pi&ugrave; fare tutte quelle cose che ti riuscivano cos&igrave; bene, nel lavoro, nel tuo gruppo di amici, con il tuo partner… &egrave; tutto sbagliato ora!
            Questa situazione ti sta incastrando, non si va pi&ugrave; avanti e si rischia addirittura di tornare indietro, dopo tutta la fatica e l’impegno che hai messo per arrivare l&igrave; dove sei ora… perch&eacute; sta succedendo? E cosa fare??
          </p>
        </div>
        <div className="with-pic">
          <p>
            <strong>Cambiare &egrave; possibile!</strong><br />
            Non tutto &egrave; perduto per&ograve;, la situazione non &egrave; irreversibile e cambiare &egrave; possibile! Anche solo il fatto che tu sia arrivat* qui a leggere queste parole, significa che hai preso coscienza di cosa stia andando storto; magari non hai ancora ben chiaro quali siano i motivi per i quali adesso ti trovi in questa situazione, ma di certo sai che non &egrave; quello che vuoi e hai deciso di fare qualcosa!
          </p>
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.photo4.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
        <div>
          <p>
            <strong>Immagina qualcosa di diverso...</strong><br />
            Come sarebbe la tua vita se riuscissi a sentirti di nuovo te stess*? Se potessi di nuovo prendere in mano la tua vita e decidere cosa farne, anzich&eacute; essere schiav* delle difficolt&agrave; che stai affrontando ora? Di certo potresti provare di nuovo la soddisfazione di riuscire nel tuo lavoro, di essere presente per i tuoi cari e di fare ci&ograve; che pi&ugrave; ti piace senza sentirti paralizzat*. La psicoterapia &egrave; ci&ograve; di cui hai bisogno per tornare ad essere la persona che vuoi essere, a realizzare a pieno il tuo potenziale e godere della tua vita in modo sereno. Tramite la mia formazione specifica posso
            insegnarti a conoscere davvero il circolo vizioso nel quale sei incastrat*, comprenderlo e soprattutto capire come si &egrave; strutturato nel tempo e perch&eacute;. Il lavoro con ogni mio paziente parte proprio da qui: dal blocco che si vive nel presente e questo &egrave; vero anche per te, individueremo il blocco e capiremo come farti ripartire! Cambiare &egrave; possibile, fai un altro passo verso la persona che vuoi essere veramente prenotando il tuo primo colloquio!
          </p>
        </div>
      </div>
      <div className="introduction">
        <Img
          className="pic"
          fluid={data.photo5.childImageSharp.fluid}
          loading="eager"
        />
      </div>
      <div className="introduction">
        <div>
          <h3>Perch&eacute; scegliere me</h3>
            <p>
              <strong>Il trattamento ti garantisce soluzioni concrete in breve tempo.</strong> Il percorso inizia con un breve periodo di valutazione iniziale, durante il quale individueremo come funziona il tuo problema e come si mantiene; una volta terminata la valutazione inizieremo subito ad utilizzare tecniche per te efficaci che diano una risposta concreta nel minor tempo possibile.
            </p>
        </div>
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.photo6.childImageSharp.fluid}
              loading="eager"
            />
          </div>
          <div>
            <p>
              <strong>Il trattamento viene sempre costruito su misura per te.</strong> La psicoterapia, proprio come un abito di alta sartoria, viene costruita su misura per la persona; il percorso che intraprenderai con me sarà ideato per te e per rispondere alle tue specifiche esigenze.
            </p>
            <p>
              <strong>Utilizzo esclusivamente tecniche efficaci e scientificamente fondate.</strong> Sono specializzato in psicoterapia cognitivo comportamentale, un modello di trattamento che si basa solo su tecniche la cui  efficacia sia stata provata e validata scientificamente.
            </p>
          </div>
        </div>
        <div className="introduction">
          <div className="with-pic">
            <div>
              <p>
                <strong>Ho a cuore i miei pazienti.</strong> La psicoterapia è il trattamento della persona e si fonda su relazioni umane: tengo tantissimo ai miei pazienti perché loro non sono solo un lavoro ma anche un valore umano.
              </p>
              <p>
                <strong>Sono sempre attivo nella ricerca e nella formazione.</strong> La psicologia non è solo la mia professione ma anche la mia passione: mi impegno a mantenermi costantemente informato sul progredire del sapere scientifico di questa affascinante disciplina, attivandomi anche in prima persona tramite interventi in convegni nazionali e internazionali e tramite la pubblicazione di articoli.
              </p>
            </div>
            <div className="pic-wrapper">
              <Img
                className="bigger-size pic"
                fluid={data.photo7.childImageSharp.fluid}
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="introduction">
        <div className="with-pic">
          <div>
            <h3>Seminari e convegni</h3>
            <p>
              <strong>27-30 maggio 2024</strong><br />
              <strong>18<sup>th</sup> International Conference on Psychology, Athens, Greece</strong><br />
              Abstract: The Psychotherapy Session as a Fractal Process: A New Conception of the Therapeutic Relationship
            </p>
            <p>
            <strong>03-04 novembre 2022</strong><br />
            <strong>Primo Congresso Nazionale CBT-Italia</strong>, organizzato dalla Societ&agrave; Italiana di Psicoterapia Cognitivo Comportamentale.<br />
            Simposio 34: Nuove frontiere della terapia cognitivo comportamentale<br />
            <strong><i>Complessità della relazione terapeutica e sua misurazione: il concetto di Dimensione Frattale Relazionale</i></strong><br />
            <strong>Firenze</strong>, Hilton Hotel
            </p>
        </div>
        <div className="pic-wrapper">
          <Img
            className="bigger-size pic"
            fluid={data.convegno1.childImageSharp.fluid}
            loading="eager"
          />
        </div>
        </div>
      </div>
      <div className="introduction">
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.convegno2.childImageSharp.fluid}
              loading="eager"
            />
          </div>
          <div>
            <p>
              <strong>23 luglio 2021</strong><br />
              <strong>31<sup>st</sup> International Conference of the <i>Society for Chaos theory in Psychology & Life Sciences.</i></strong><br />
              Jacopo Biraschi, Independent Scholar: <i>Relational Fractal Dimension. From the complexity of the psychological interview to the emergence of the therapeutic relationship</i> (40 minuti, simposio).
            </p>
          </div>
        </div>
      </div>
      <div className="introduction">
        <div className="with-pic">
          <div>
            <p>
              <strong>12 ottobre 2019</strong><br />
              <strong>VIII Forum sulla Formazione in Psicoterapia</strong>, organizzato da “Scuole di Specializzazione in Psicoterapia Cognitiva” (APC, SPC, AIPC, IGB, SICC)<br />
              <i><strong>Dipendenza Affettiva Patologica:</strong> utilità del costrutto, stato dell'arte e prospettive future</i><br />
              <strong>Assisi</strong> (PG), Cittadella Ospitalità, via degli Ancajani 3
            </p>
            <p>
              <strong>08 febbraio 2019</strong><br />
              <strong>Accademia Georgica di Treia</strong><br />
              <strong><i>Il recupero e la responsabilizzazione dei detenuti attraverso il reinserimento lavorativo</i></strong><br />
              Auditorium IIS Matteo Ricci, <strong>Macerata</strong>.<br />
              In rappresentanza dell'Istituto Croce Bianca.
            </p>
          </div>
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.convegno3.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
      </div>
      <div className="introduction">
        <h3>Pubblicazioni internazionali</h3>
        <p>
          <strong>Title:</strong> <i>For a Theory of the Psychotherapeutic Process: Epistemology of Recursion and Relational Fractality</i><br />
          <strong>Journal:</strong> <i>Nonlinear Dynamics, Psychology and Life Sciences</i>, Editor in Chief Stephen Guastello Phd, published by the <strong>Society for Chaos Theory in Psychology & Life Sciences</strong>, Pewaukee, WI 53072, <strong>USA</strong>.
          Submitted: 02.01.2024 - Reviewed 05.02.2024 - Accepted for publication 25.02.2024 - <strong>Published October 2024</strong>
        </p>
        <p>
          <strong>Title:</strong> <i>Relational Fractal Dimension. From the Complexity of the Psychological Interview to the Emergence of the Therapeutic Relationship.</i><br />
          <strong>Journal:</strong> <i>Nonlinear Dynamics, Psychology and Life Sciences</i>, Editor in Chief Stephen Guastello Phd, published by the <strong>Society for Chaos Theory in Psychology & Life Sciences</strong>, Pewaukee, WI 53072, <strong>USA</strong>.
          Submitted: 13.03.2021 - Reviewed 07.05.2021 - Accepted for publication 03.06.2021 - <strong>Published January 2022</strong>
        </p>
      </div>
      <div className="introduction extra-margin">
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.heroPic.childImageSharp.fluid}
              loading="eager"
            />
          </div>
          <p>
            Ciao, sono Jacopo Biraschi<br />
            Sono uno psicologo clinico specializzato in psicoterapia cognitivo-comportamentale.<br />
            Ti invito a scoprire i servizi che offro e a contattarmi per parlarmi di te.
          </p>
        </div>
      </div>
      <div className="call-to-action">
        <PrenotaOra />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "lina-trochez-ktPKyUs3Qjs-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo2: file(relativePath: { eq: "noah-silliman-gzhyKEo_cbU-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo3: file(relativePath: { eq: "dev-asangbam-_sh9vkVbVgo-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo4: file(relativePath: { eq: "pablo-heimplatz-EAvS-4KnGrk-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo5: file(relativePath: { eq: "marc-najera-Cj2d2IUEPDM-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroPic: file(relativePath: { eq: "maglione_bianco.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rhoMaps: file(relativePath: { eq: "green-chameleon-s9CC2SKySJM-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    milanMaps: file(relativePath: { eq: "samantha-gades-BlIhVfXbi9s-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    womanTablet: file(
      relativePath: { eq: "brooke-cagle-uWVWQ8gF8PE-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo6: file(relativePath: { eq: "getty-images-zf_eiBeRhhc-unsplash-square.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo7: file(relativePath: { eq: "galina-nelyubova-d3P2kQJIxqc-unsplash-square.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    convegno1: file(relativePath: { eq: "convegno1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    convegno2: file(relativePath: { eq: "convegno2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    convegno3: file(relativePath: { eq: "convegno3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
